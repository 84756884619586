.breadcrumb-wrapper {
  display: block;
  margin-top: 10px;
  margin-bottom: 30px;

  .title {
    font-size: 24px;
    color: black;
    margin-bottom: 15px;
    font-weight: 500;

    .itemId {
      font-size: 12px;
    }
  }

  .breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline;

      &:not(:last-of-type)::after {
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        margin: 0 5px;
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .breadcrumb-wrapper {
    .title {
      display: none;
    }
  }
}
