// @import '/node_modules/swiper/swiper.scss';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
// @import 'swiper/scss/pagination';

// BEGIN - Boostrap
@import './bootstrapImporter.scss';
// END - Boostrap

@import './general';
@import './variables';
@import './layout';
@import './typography';
@import './tables';
@import './cards';
@import './breadcrumbs';
@import './toastr';

.mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .mat-list-item {
    &.active {
      background: rgba(0, 0, 0, 0.4);
    }

    .mat-list-item-content {
      padding: 0 40px !important;
    }
  }
}

.mat-expansion-indicator {
  &::after {
    color: #c9c9c9 !important;
  }
}
