.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-main {
  color: var(--color-main);
}

a {
  text-decoration: none;
  color: var(--color-main);

  &:hover {
    color: var(--color-main-dark);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: bold !important;
  color: var(--text-primary-lighter-color);
  letter-spacing: 0 !important;
}

h1,
.h1 {
}

h2,
.h2 {
}

h3,
.h3 {
}

h4,
.h4 {
  font-size: 18px;
}

.underline {
  text-decoration: underline;
}
