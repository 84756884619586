body,
html {
  margin: 0;
  background: white;

  &.wbg {
    background: white;
  }
}

// Icons
.mat-icon.fal,
.mat-icon.fa {
  // vertical-align: text-top;

  width: auto;
  height: auto;
  display: var(--fa-display, inline-block);
  overflow: visible;
  // height: auto !important;
  // width: auto !important;
}

// .mat-icon.fal,
// .mat-icon.fab,
// .mat-icon.fas {
//   vertical-align: initial !important;
// }

button mat-icon.mat-icon,
a mat-icon.mat-icon {
  font-size: auto;
  width: auto;
}

// Spinner
mat-spinner-button {
  .mat-button-wrapper {
    flex-direction: row-reverse;

    .mat-icon {
      padding-right: 0px;
      padding-left: 5px;
    }
  }
}

// Form & Fields
.mat-error {
  letter-spacing: 0px;
}
.mat-form-field {
  color: black;
}

.form-wrapper {
  .mat-form-field {
    margin-bottom: 20px;
  }
  .small-row {
    .mat-form-field {
      margin-bottom: 0px;
    }
  }
}
.mat-form-field-outline {
  background-color: white;
}
.mat-radio-button + .mat-radio-button {
  margin-left: 20px;
}

.ng-dropdown-panel.ng-select-bottom {
  background: white;
  box-shadow: 2px 3px 5px 2px rgba(0, 0, 0, 0.1);

  .ng-option {
    padding: 5px 10px;

    &.ng-option-marked {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
.mat-form-field-label,
.mat-select-value-text span,
.mat-hint {
  letter-spacing: 0px;
}
.mat-form-field-label {
  color: var(--color-main);
}
.ngx-mat-timepicker-tbody .mat-input-element {
  text-align: initial !important;
}
.mat-select-min-line {
  overflow: visible;
  white-space: normal;
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after,
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.75);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-main);
}

// BEGIN - Issue #60
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: #00b5b7;
}

.mat-form-field-label {
  color: rgb(0 0 0 / 30%);
}
// END - Issue #60

// Dialogs
.mat-dialog-container {
  position: relative;
}

mat-dialog-container {
  overflow: visible !important;

  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 25px;
    cursor: pointer;
  }
}
// Right dialog (.full-dialog)
.cdk-overlay-pane.full-dialog {
  .mat-dialog-container {
    overflow: scroll !important;

    .mat-dialog-title {
      padding: 0px;
      margin-bottom: 0;

      > .mat-icon {
        margin-right: 10px;
      }

      .close-button {
        right: 25px;
        top: 25px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .mat-dialog-content {
      margin: 0;
      padding: 0px;
      max-height: 100vh;
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 20px;
    }
  }
}

// Right dialog (.right-dialog)
.cdk-overlay-pane.right-dialog {
  position: absolute !important;
  right: 0;

  .mat-dialog-container {
    border-radius: 0 !important;
    padding: 0;

    .mat-dialog-title {
      padding: 20px;
      background: #f1f0f5;

      > .mat-icon {
        margin-right: 10px;
      }

      .close-button {
        right: 10px;
        top: 7px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .mat-dialog-content {
      margin: 0;
      padding: 20px;
      max-height: 100vh;
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 20px;
    }
  }
}

// Ticketing
.ticket-status-open {
  background: #28c76f;
  padding: 0px 5px;
  color: white;
  font-size: 0.7rem;
  word-break: keep-all;
  display: inline-block;
  border-radius: 2px !important;
  font-weight: 600;
}
.ticket-status-pendingUser {
  background: #ff9f43;
  padding: 0px 5px;
  color: white;
  font-size: 0.7rem;
  word-break: keep-all;
  display: inline-block;
  border-radius: 2px !important;
  font-weight: 600;
}
.ticket-status-closed {
  background: #d7d5db;
  padding: 0px 5px;
  color: white;
  font-size: 0.7rem;
  word-break: keep-all;
  display: inline-block;
  border-radius: 2px !important;
  font-weight: 600;
}

// Dashboard misc
.smallStat {
  font-size: 14px;
  opacity: 0.6;
  font-style: italic;
}

// Plant maps
.gm-style {
  font-family: 'Montserrat' !important;

  .gm-style-iw-tc {
    transform: translateY(-50%) rotate(90deg);
    z-index: 10;
    left: -168px;
    top: -144px;
  }

  .gm-style-iw-t::after {
    box-shadow: none;
    left: -150px;
    top: -143px;
  }

  .gm-style-iw-c {
    min-width: 300px !important;
    min-height: 420px !important;
    padding: 0;

    .gm-style-iw-d {
      // overflow: auto !important;
      // padding: 0;

      > div {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    > button {
      background-color: #fff !important;
      padding-left: 2px !important;
      top: 3px !important;
      right: 3px !important;
      width: 30px !important;
      height: 30px !important;

      span {
        width: 25px !important;
        height: 25px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0 !important;
      }
    }
  }
}

// Misc
.mat-sort-header-arrow {
  color: white;
}
.clickable {
  cursor: pointer;
}
.clickableLink {
  cursor: pointer;
  color: var(--color-main);
}
.user-card {
  .mat-card-header-text {
    display: flex;
    align-self: center;
  }
}
.shadow {
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
}
* {
  border-radius: 0px !important;
}
button,
.button {
  border-radius: 2px !important;
  box-shadow: none !important;
  // border: none !important;

  &.mat-stroked-button.mat-warn {
    border: 1px solid #ea5455 !important;
  }
}
.word-break-all {
  word-break: break-all !important;
}
.warn-bg {
  border-color: var(--warn-color) !important;
  background-color: var(--warn-color) !important;
  color: white !important;
}

@media (max-width: 767px) {
  mat-spinner-button,
  mat-spinner-button button {
    width: 100%;
    display: block;
  }

  a.mat-button.d-block,
  a.mat-raised-button.d-block {
    width: 100%;
  }
}
