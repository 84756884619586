.table-wrapper {
  max-width: calc(100vw - 30px);

  .spinner-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
  }

  .table-title {
    margin: 0;
    padding: 20px 30px;
    padding-bottom: 0;

    +.table-toolbar {
      padding-top: 0;
    }
  }
  .table-toolbar {
    margin: 0;
    padding: 20px 30px;
  }

  .table-responsive {
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;

    .filter {
      margin-top: 10px;
      display: block;
      max-width: 90%;
    }

    .mat-footer-cell {
      padding: 0 10px;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    max-width: 120px;
  }
}

.mat-sort-header-content {
  white-space: nowrap;
}
