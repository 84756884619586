mat-card,
mat-card.mat-card,
.mat-card {
  padding: 0px !important;
  border-radius: 5px !important;
  overflow: hidden;

  .card-header {
    // border-bottom: 1px solid #ebedf3;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    border-bottom: 1px solid #dddddd;

    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 0;
    }

    > .col-12:first-child {
      padding-left: 0;
    }

    > .col-12:last-child {
      padding-right: 0;
    }

    mat-card-title {
      display: block;
      width: 100%;
      font-size: 18px;
      margin-bottom: 0;

      small {
        font-size: 0.8rem;
      }
    }

    mat-card-subtitle {
      font-size: 14px;
      margin-bottom: 0;
      opacity: 0.7;
    }
  }

  mat-card-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  mat-card {
    .card-header,
    mat-card-content {
      padding: 15px !important;
    }
  }
}
