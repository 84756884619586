@import '../../assets/styles/variables';

$pageContentPadding: 20;
.page {

  .page-content {
    display: flex;
    //                        HEADER_HEIGHT - FOOTER_HEIGHT - PADDING x 2
    min-height: calc(100vh - #{$headerHeight}px - 64px);
    padding: #{$pageContentPadding}px;
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .page {
    min-width: 100vw;

    .page-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
